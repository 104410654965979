<template>
    <div class="sitebg">
        <div class="siteDiv">
            <div class="siteHeader">

                <div >
                    <!-- <i class="siteICon"></i> -->
                    <div class="siteInfoDiv">
                        <h5>{{siteName}}</h5>
                        <p>编号：{{siteNo}}</p>
                        <p>所属部门：{{siteDepart}}</p>
                        <p>站长姓名：{{masterName}}</p>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>
    export default {
        name: "headerSiteInfo",
        data(){
            return{

            }

        },
        props:['bgColor','siteName','siteNo','siteDepart','masterName']
    }
</script>

<style scoped lang="less">
    .sitebg{
    }
    .VDS-ShadowBg{

    }
    .siteDiv{
    }
    .siteHeader{
        width: calc(100% - 24px);
        margin: 0 auto;
        box-shadow:0px 1px 30px 5px rgba(0, 0, 0, 0.04) ;
        border-radius: 2px;
       display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px;

        .siteICon{
            display: inline-block;
            vertical-align: middle;
            width:68px;
            height:68px;
            background:url("../../assets/img/siteManage/headerIcon.png");
            background-size: 100% 100% ;
            border-radius:4px;
        }
        .siteInfoDiv{
            display: inline-block;
            vertical-align: middle;
            margin-left: 12px;

            h5{
                font-size:15px;
                font-family:PingFangSC-Medium,PingFang SC;
                font-weight:bold;
                color: #333333;
            }
            p{
                font-size:13px;
                font-family:PingFangSC-Regular,PingFang SC;
                font-weight:400;
                color: #999999;
                margin-top: 4px;

            }

        }
    }

</style>