<template>
  <div class="waitSign">
    <div  @click="siteInfoClick(siteInfo.stationNo)">
      <header-site-info  bg-color="#63B57E" :siteName="siteInfo.stationName" :siteNo="siteInfo.areaNo" :siteDepart="siteInfo.deptName" :masterName="siteInfo.masterName"></header-site-info>

    </div>
    <div class="content">
      <van-field label="站点状态" :value="siteInfo.stationStatus" readonly input-align="right"/>
      <van-field label="合作银行" v-if="siteInfo.cooperationBank != null" :value="siteInfo.cooperationBank" readonly input-align="right"/>
      <van-field label="站点管理责任人" label-width="120px" :value="siteInfo.marketerName" readonly input-align="right"/>
    </div>
    <!-- 等待审批/审批失败 -->
    <step-box v-if="siteInfo.stationStatusName != 'SIGNED'" :stationStatusName="siteInfo.stationStatusName" :successFlag="siteInfo.approve" :stepArr="siteInfo.stepList" :refuseInfo="siteInfo.comment" :stepDesc="siteInfo.trialName" style="padding-bottom: 3rem;"></step-box>
    <div class="footer" v-if="!isHideBottom">
      <div class="agreement flex">
        <van-checkbox v-model="checked" shape="square" checked-color="#3F7C53" icon-size="0.3rem"></van-checkbox>
        <div class="agreement_item">我已阅读
          <span v-for="(item, index) in agreementListArr" :key="index" @click="protocolClick(item.agreementNo)">{{item.agreementName}}</span>
        </div>
      </div>
      <van-button type="info" color="#3F7C53" @click="signClick">签约</van-button>
    </div>
  </div>
</template>

<script>
import { Icon, Button, Field, Toast, Checkbox, Tab, Tabs } from 'vant'
import StepBox from '@/components/StepBox.vue'
import { getSiteByMasterNo, getAgreementInfoByType, signContract, getAgreementListByStationNo } from '@/utils/api'
import common from '@/utils/common'
import HeaderSiteInfo from "../../components/siteInfo/headerSiteInfo";
import {appAction, uwStatisticAction} from "../../utils/util";
import {AUTH_SUBMIT} from "../../utils/constantNum";
export default {
  components:{
    StepBox,HeaderSiteInfo,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Field.name]: Field,
    [Toast.name]: Toast,
    [Checkbox.name]: Checkbox,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  data() {
    return {
      logoImg: require('../../assets/img/logo_f.png'),
      checked: false,
      stepArr: [],
      stepDesc: '注：等待站长签约',
      siteActive: '1',
      siteInfo: {},
      isHideBottom: false,
      agreementListArr:[]
    }
  },
  activated() {
    if(common.getItem('signAgent') === true){
      this.checked = true
    }
  },
  mounted(){
    this.isHideBottom = this.$route.query.isHideBottom == '1'
    this.getsiteInfo()
    if(common.getItem('signAgent') === true){
      this.checked = true
    }
    uwStatisticAction('/waitSign','站长签约')
  },
  methods:{
    togetAgreementListByStationNo() {
      getAgreementListByStationNo({stationNo: this.siteInfo.stationNo}).then(res=>{
        if(res.code ==200){
          if(res.data && res.data.businessCode ==200){
           this.agreementListArr = res.data.data
          }
        }
      })
    },
    signClick() {
      if(!this.checked){
        Toast('请勾选协议')
        return
      }
      if(!common.getItem('signAgent')){
        Toast('请查看并签署协议')
        return
      }
      signContract({
        token: common.getItem('wtToken'),
        stationNo: this.siteInfo.stationNo,
        uniqueNo: common.getItem('uniqueNo'),
        agreementList: this.agreementListArr,
      }).then(res=>{
        if(res.code ==200){
          if(res.data && res.data.businessCode ==200){
            if (common.getItem('jumpFrom') != undefined) {
              appAction(AUTH_SUBMIT)
            }
            this.$router.push({name: 'submitted'})
            common.setItem('authStep', 'signed')
            localStorage.removeItem('signAgent')
          }
        }
      })
    },
    getsiteInfo() {
      getSiteByMasterNo({
        masterNo: common.getItem('uniqueNo')
      }).then(res=>{
        if(res.code ==200){
          if(res.data && res.data.businessCode ==200){
            this.siteInfo = res.data.data
            if(this.siteInfo.stepOne) this.stepArr.push({step: this.siteInfo.stepOne,state:this.siteInfo.stepOneColorType})
            if(this.siteInfo.stepTwo) this.stepArr.push({step: this.siteInfo.stepTwo,state:this.siteInfo.stepTwoColorType})
            if(this.siteInfo.stepThree) this.stepArr.push({step:this.siteInfo.stepThree,state:this.siteInfo.stepThreeColorType})
            if(this.siteInfo.stepFour) this.stepArr.push({step:this.siteInfo.stepFour,state:this.siteInfo.stepFourColorType})
            this.togetAgreementListByStationNo()
          }
        }
      })
    },
    siteInfoClick() {
      this.$router.push({name: 'siteInfo', query:{stationNo: this.siteInfo.stationNo}})
    },
    protocolClick(agreementNo) {
      getAgreementInfoByType({
        token: common.getItem('wtToken'),
        agreementNo,
        uniqueNo: common.getItem('uniqueNo') ? common.getItem('uniqueNo') : common.getItem('masterNo')
      }).then(res=>{
        if(res.code === 200){
          if(res.data.businessCode && res.data.businessCode === 200){
            this.$router.push({path:res.data.data.agreementPath,query:{stationNo:this.siteInfo.stationNo,sign:'sign',agreementParams:res.data.data.agreementParams}})
          }
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.waitSign{
  height: 100%;
  /*background: #f5f5f5;*/
  font-size: 0.3rem;
  box-sizing: border-box;
  padding-top: 0.3rem;
  .flex{
    display: flex;
  }
  .title{
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem 0.3rem;
    border-top: 0.1rem solid #555;
    background: #fff;
    .logo-img{
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.3rem;
    }
    .title_left{
      align-items: center;
    }
    .title_mid{
      margin-left: 0.3rem;
    }
  }
  .content{
    margin-top: 0.3rem;
  }
  .footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 10;
    .van-button{
      width: 100%;
    }
    .agreement{
      padding: 0.2rem 0.3rem;
      align-items: flex-start;
      font-size: 0.24rem;
      span{
        color: #1989fa;
      }
      .agreement_item{
        margin-left: 0.1rem;
        span{
          color: #3F7C53;
        }
      }
      .van-checkbox{
        flex: 0 0 0.3rem;
      }
    }
  }
  .time-desc{
    margin: 0.2rem;
    font-size: 0.26rem;
  }
}
</style>
<style>
  .waitSign .van-field__label{
    font-size:15px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color: #333333;
  }
  .waitSign .van-field__value .van-field__control{
    font-size:15px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color: #999999;
  }
  .waitSign .van-cell{
    border-bottom:1px solid #eeeeee;
  }
</style>
