<template>
  <div class="step-box">
    <div >
      <div class="step-item"
           :class="{'stepEnd': item.stepColorType === 'END','stepFail':item.stepColorType === 'FAIL','stepIng':item.stepColorType === 'ING', 'lastItem':index === stepArr.length-1 }"
           v-for="(item, index) in stepArr" :key="index">
        <div class="circle">
          <span class="smallCircle"></span>
        </div>
        <img :src="item | imgIcon">{{item.stepName}}
        <div class="time" v-if="item.oprTime != '-----' ">{{item.oprTime}}</div>
      </div>
    </div>
    <div :class=" successFlag ? {'step-desc' : successFlag == 'AGREE' , 'step-desc-failed step-desc-default': successFlag == 'REJECT'}: 'step-desc-default'">{{stepDesc ? stepDesc : ''}}</div>
    <div v-show="stationStatusName === 'JOIN_FAIL' || stationStatusName === 'SIGN_FAIL' " :class="{ 'step-desc' : successFlag == 'AGREE' , 'step-desc-failed': successFlag == 'REJECT'}">{{refuseInfo ? '未通过原因：'+ refuseInfo : ''}}</div>

  </div>
</template>

<script>
  import passImg from '../assets/img/common/pass.png'
  import waitImg from '../assets/img/common/wait.png'
  import failImg from '../assets/img/common/failed.png'
export default {
  props:{
    stepArr:Array,
    successFlag: true,
    stepDesc: null,
    refuseInfo:null,
    stationStatusName:null,
    passImg
  },
  filters:{
    imgIcon(item){
      if (item.stepColorType === "END"){
        return passImg
      }else if (item.stepColorType === 'FAIL'){
        return  failImg
      }else {
        return  waitImg
      }


    }

  },
  mounted() {
    console.log('successFlag:', this.successFlag)
  },
  data() {
    return {
      siteStatusList: {
        "INSPECTION": "考察",
        "JOINTRIAL": "加盟初审",
        "JOINREVIEW": "加盟复审",
        "SIGNBANK": "签约",
        "CUSSERVREVIEW": "客服审核",
        "END": "完成"
      },
    }
  },
}
</script>

<style lang="less" scoped>
.step-box{
  margin: 0.5rem 0.5rem auto;
  .step-item{
    background: #F1F7F4;
    color: #22b573;
    margin-left: 1rem;
    height: 1.4rem;
    position: relative;
    margin-top: 0.24rem;
    padding: 0.24rem 0.32rem;;
    border-radius:8px;
    font-size:15px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color: #30BF78;
    .time{
      color: #999;
      font-size: 0.24rem;
      padding-left: .8rem;
    }
    .circle{
      display: inline-block;
      position: absolute;
      border-radius: 50%;
      left: -0.58rem;
      top: 0.42rem;
      width:14px;
      height:14px;
      background:#ffffff;
      box-shadow:0px 3px 5px -1px rgba(186,194,210,1);
    }
    .smallCircle{
      border-radius: 50%;
      display: inline-block;
      width:8px;
      height:8px;
      background:#30BF78;
      position: absolute;
      left: 3px;
      top: 3px;
      z-index: 1;
    }
    &::after{
      content: "";
      position: absolute;
      width: 0.01rem;
      height: 1.6rem;
      border-left: 1px dashed #999 ;
      left: -0.45rem;
      top: -0.25rem;
      z-index: -1;
    }
    img{
      width: 30px;
      height: 30px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;

    }
  }
  .lastItem{
    &::after{
      content: "";
      position: absolute;
      width: 0.01rem;
      height: 0.8rem;
      border-left: 1px dashed #999 ;
      left: -0.45rem;
      top: -0.25rem;
      z-index: 0;
    }

  }
  .step-failed{
    background: #f8e9cd;
    color: #ff0000;
    &::before{
      content: "";
      position: absolute;
      width: 0.3rem;
      height: 0.3rem;
      background: #ff0000;
      border-radius: 50%;
      left: -0.6rem;
      top: 0.35rem;

    }
    &::after{
      content: "";
      position: absolute;
      width: 0.01rem;
      height: 0.6rem;
      background: #22b573;
      left: -0.45rem;
      top: -0.25rem;
    }
  }
  .step-desc{
    margin-left: 1rem;
    margin-top: 0.5rem;
    font-size: 0.26rem;
    position: relative;
    font-size:13px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color: #999999;
    &::after{
      content: " ";
      position: absolute;
      width: 0.01rem;
      height: 0.5rem;
      border-left: 1px dashed #999;
      left: -0.45rem;
      top: -0.8rem;

    }
  }
  .step-desc:last-child{
    margin-left: 1rem;
    margin-top: 0.5rem;
    font-size: 0.26rem;
    position: relative;
    &::after{
      display: none;

    }
  }
  .step-desc-failed{
    margin: 0.1rem 0 0.1rem 1rem;
    font-size: 0.26rem;
    word-break: break-all;
    color: #999;
  }
  .step-desc-default{
    margin: 0.24rem 0 0.1rem 1rem;
    color: #999; 
    font-size: 0.26rem;
  }
  .stepEnd{
    background: #F1F7F4;
    color: #30BF78;
    .smallCircle{
      background:#30BF78;
    }
  }
  .stepIng{
    background: #FFF8E9;
    color: #FAAD14;
    display: flex;
    align-items: center;
    .smallCircle{
      background:#FAAD14;
    }
  }
  .stepFail{
    background: #FFEAE6;
    color: #F4664A;
    .smallCircle{
      background:#F4664A;
    }
  }
}

</style>
